/**
 * @Author: Kyles Light
 * @Date: 2017-03-27 15:15:47.600
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-03-29 下午03:48:11
 */
import marked from 'marked';

import WebViewInterface from 'ssr-common/util/webview/yqg-webview-interface';

export default {
    name: 'YqgMdPreview',

    data() {
        return {
            htmlStr: ''
        };
    },

    props: {
        mdText: {
            type: String,
            default: ''
        }
    },

    methods: {
        adaptWideTable() {
            try {
                Array.from(document.querySelectorAll('.wide-table-followed')).forEach(el => {
                    const nextEl = el.nextElementSibling;

                    if (nextEl.tagName.toLowerCase() !== 'table') return;

                    Array.from(el.children).forEach(child => {
                        el.removeChild(child);
                    });

                    el.appendChild(nextEl);
                });
            } catch (error) {
                // ignore
            }
        },

        updateHtml() {
            const vm = this;
            const {mdText, htmlStr} = vm;
            try {
                const markedHtml = marked(mdText);
                if (htmlStr !== markedHtml) {
                    vm.htmlStr = markedHtml;
                }

                vm.$nextTick(() => {
                    vm.bindEvents();
                    vm.adaptWideTable();
                });
            } catch (err) {
                // ignore err
            }
        },

        bindEvents() {
            const vm = this;
            vm.$refs.container?.addEventListener('click', clickTarget => {
                const targetClass = clickTarget.target && clickTarget.target.className;
                const targetValue = clickTarget.target.value;
                if (targetClass === 'browser-link') {
                    clickTarget.preventDefault();
                    WebViewInterface.copyToClipboard(targetValue);
                    vm.$toast.info('copy to the clipboard!');
                }
            });
        }
    },

    watch: {
        mdText() {
            this.updateHtml();
        }
    },

    created() {
        if (this.mdText) {
            this.htmlStr = marked(this.mdText);
        }
    },

    mounted() {
        this.updateHtml();
    }
};
